<template>
  <div class="w-full height-container-mapbox">
    <div id="mapbox"></div>
  </div>
</template>
<script>
import Mapbox from '@/mixins/map/mapbox'
import { mapGetters } from 'vuex'
import parcelle from '@/mixins/parcelle'
import batiment from '@/mixins/batiment'

export default {
  data() {
    return {
      id_parcelles_selected:[]
    }
  },
  computed: {
    ...mapGetters({
      features_autour: 'parcelle/features_autour',
      parcelles_autour: 'parcelle/parcelles_autour',
      current_parcelle: 'parcelle/current_parcelle',
      parcelles_voisines: 'parcelle/parcelles_voisines',
      parcelles_selected:'parcelle/parcelles_selected',
      map: 'map/map',
      address: 'address/address',
      altimetries:'parcelle/altimetries',
      infos_parcelles: 'address/infos_parcelles', 
    }),
  },
  mixins: [Mapbox, parcelle,batiment],
  mounted() {
    this.loadMap()
      ? this.loadDataToMap()
      : this.map.on('load', this.loadDataToMap)
  },
  methods: {
    loadDataToMap() {
      
      let own_parcelles = [this.current_parcelle, ...this.parcelles_voisines]
      let fc_own_parcelles = this.$turf.featureCollection(own_parcelles)
      this.fitBoundsGeojsonInMap(fc_own_parcelles)
      this.$emit('addMakerslengthsWall', this.$turf.union(...own_parcelles))
      this.addLayerGeoParcelles()
      this.map.on('click', this.selectOrUnselectParcelleVoisines)
      this.setPaintParcellesSelected()
      this.$store.commit('map/MAP_STYLLE_LOADED', true)
    },
      selectOrUnselectParcelleVoisines(e) {
      let features = this.map.queryRenderedFeatures(e.point, {
          layers: [this.source_and_layers_geo_parcelles[1]],
      })
      if (features.length) {
                  let id_parcelle_selected = features[0].properties.id
                  if (
                      !_.includes(
                          this.parcelles_selected.map((item) => item.properties.id),
                          id_parcelle_selected
                      )
                  ) {
                    this.$store.commit('parcelle/IS_ALTIMETRIES_LOADING', true)
  
                      this.$http.get('/grand-publics/get-parcelles/' + id_parcelle_selected, {
                          params: {
                              with_altimetrie: true,
                          },
                      }).then((response) => {
                      this.$store.commit('address/INFOS_PARCELLES',  [...this.infos_parcelles,...response.data.cadastre_principal])
          
                        this.$store.commit('parcelle/ALTIMETRIES', [...response.data.altimetrie.features,...this.altimetries])
                        this.$store.commit('parcelle/IS_ALTIMETRIES_LOADING', false)
                          let parcelle = response.data.cadastre_principal[0].parcelle
                          parcelle.properties.ref_cadastrale = this.getRefParcelle(parcelle)
                          let parcelle_selected = [...this.parcelles_selected , parcelle]   
                          this.setParcellesState(parcelle_selected)
                          this.setPaintParcellesSelected()
                          this.$emit('updateAddress')
                      })
                  } 
                  
                  else {
                    this.$store.commit('address/INFOS_PARCELLES', this.infos_parcelles.filter((item) =>item.parcelle.id !== id_parcelle_selected ))
                    let parcelles_selected = this.parcelles_selected.filter(
                          (parcelle) => parcelle.properties.id !== id_parcelle_selected
                      )
                     let ids_parcelles =  parcelles_selected.map((item) => item.properties.id)
                      this.setParcellesState(parcelles_selected)
                      this.setPaintParcellesSelected()
                      if (ids_parcelles.length) {
                        this.$store.commit('parcelle/IS_ALTIMETRIES_LOADING', true)
                          this.$http.get('/grand-publics/get-parcelles/' + ids_parcelles.join(','), {
                              params: {
                                  with_altimetrie: true,
                              },
                          }).then((response) => {
                              this.$store.commit('parcelle/ALTIMETRIES', response.data.altimetrie.features)
                              this.$store.commit('parcelle/IS_ALTIMETRIES_LOADING', false)
                              this.$emit('updateAddress')

                          })
                      }
                  }

                 
             
      }
  },
    setParcellesState(parcelles){
      this.$store.commit('parcelle/CURRENT_PARCELLE', this.getParcellePrincipalThroughCenterOfAllParcelles(parcelles))
      this.$store.commit('parcelle/PARCELLES_VOISINES', parcelles.filter((parcelle) => parcelle.properties.id !== this.current_parcelle.properties.id))
    },
    setPaintParcellesSelected() {
        if (!this.parcelles_selected.length) {
            this.map.setPaintProperty(this.source_and_layers_geo_parcelles[1], 'fill-color', '#8F6DB0')
        } else {
            this.map.setPaintProperty(this.source_and_layers_geo_parcelles[1], 'fill-color', [
                'match',
                ['get', 'id'],
                this.parcelles_selected.map((parcelle) => parcelle.properties.id),
                '#00b7ab',
                '#8F6DB0',
            ])
        }
    },
 

  },
  watch: {
    own_parcelles: {
      handler() {
        let own_parcelles = [
          this.current_parcelle,
          ...this.parcelles_voisines,
        ].filter((parcelle) => parcelle)

        this.$emit(
          'addMakerslengthsWall',
          own_parcelles.length ? this.$turf.union(...own_parcelles) : null,
        )
        this.$emit('resetDetailsParcelles')   
      },
    },
    map_style_loaded(is_loaded) {
      if (is_loaded) {
        this.map.on('click', this.selectOrUnselectParcelle)
      }
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.map.off('load', this.loadDataToMap)
      this.map.off('click', this.selectOrUnselectParcelleVoisines)
    }
    this.$store.commit('map/MAP_STYLLE_LOADED', false)
  },
}
</script>
